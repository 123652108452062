import React from "react"

import SEO from "../components/common/seo"
import Breadcrumbs from "../components/common/breadcrumbs";
import LayoutSecondary from "../components/common/layout-secondary";
import {graphql} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-gtag";

const breadcrumbs = [{
    text: 'Políticas corporativas',
    slug: '/es/politicas',
    active: false,
},{
    text: 'Política de calidad',
    active: true,
}
];

const QualityPage = ({data, pageContext})  => (
    <LayoutSecondary pageContext={pageContext} cookies={data.cookies}>
        <SEO title="Política de calidad - Tecalis"
             description={"La calidad de nuestras soluciones tecnológicas y la satisfacción de los usuarios tienen para nosotros rango preferencial y nos sirven para orientar nuestras actividades."}/>
        <Breadcrumbs breadcrumbs={breadcrumbs}/>

        {/** Política de calidad
         ================================================== */}
        <section className="pt-8 pt-md-9">
            <div className="container">

                {/** Heading */}
                <h1 className="display-4 font-weight-bold spacing">
                    Política de calidad
                    <br/><br/>
                </h1>

                <div className="row check-list-qa">
                    <div className="col-12 col-md-12">

                        {/** Decoration */}
                        <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 mobile-qa"
                             style={{position: "absolute", zIndex: -10, top: "50px"}}>
                            <svg width="185" height="186" viewBox="0 0 185 186" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="2" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="182" r="2" fill="#f43c51"/>
                            </svg>
                        </div>

                        {/** Image */}
                        <img src={require("../images/calidad.png").default} alt="..." className="img-fluid" data-aos=""/>
                        <br/><br/><br/>
                        <div className="row">
                            <div className="col-xl-9">
                                {/** Texto */}
                                <p className="font-size-lg text-black-80 mb-6 spacing">
                                    <b>Tecalis</b> define como política general ofrecer servicios que satisfagan
                                    plenamente a los clientes, con un pleno compromiso de mantenimiento de la
                                    confidencialidad, integridad y disponibilidad de la información, siempre en
                                    estricto cumplimiento de los requisitos legales y reglamentarios vigentes; así
                                    como, los Códigos, Normas y Especificaciones aplicables
                                    contractualmente. <b>Tecalis</b> promueve el mantenimiento y mejora continua de
                                    la gestión y del desempeño de un Sistema Integrado de Gestión de la Calidad y
                                    Seguridad de la Información poniendo en juego todos los recursos que considere
                                    necesarios para el fortalecimiento de estas bases y la consecución de nuestros
                                    objetivos, así como el mantenimiento de la confidencialidad, integridad y
                                    disponibilidad de la información.</p>

                                <p className="font-size-lg text-black-80 mb-6 spacing">Las directrices que emanan de
                                    esta Política se pueden resumir en los siguientes principios básicos:</p>

                            </div>

                            <div className="col-xl-3">

                                <div className="card border-card mb-2">
                                    <div className="card-body my-auto">
                                        <OutboundLink href="https://www.aenor.com/certificacion/certificado/?codigo=199259" rel="noreferrer noopener" target="_blank">
                                            <img src={require("../img/9001-logo.png").default} alt="..." className="img-fluid" data-aos=""/>
                                        </OutboundLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul>
                            <br/>
                            <h2 className="font-weight-bold spacing text-tecalis">
                                <li>Orientación al cliente</li>
                            </h2>
                            <br/>

                            <p className="font-size-lg text-black-80 mb-6 spacing">Los clientes
                                de <b>Tecalis</b> son nuestra razón de ser, por lo que no solamente hemos de atender
                                y satisfacer las necesidades manifestadas por ellos, sino que debemos de ser capaces
                                de adelantarnos a sus expectativas, mediante un contacto permanente que nos permita
                                evaluar la percepción que los clientes tienen sobre nuestros servicios, para
                                mejorarlos continuamente. </p>

                            <br/>
                            <h2 className="font-weight-bold spacing text-tecalis">
                                <li>Profesionalidad</li>
                            </h2>
                            <br/>

                            <p className="font-size-lg text-black-80 mb-6 spacing">Debido a las características de
                                las actividades que desarrollamos, se requiere una alta profesionalización de los
                                distintos equipos de trabajo, para adecuarnos a las características y necesidades de
                                cada cliente. Una de nuestras constantes de actuación es la adaptación permanente a
                                los nuevos métodos y técnicas que existen en el mercado y la mejora continua de
                                nuestros procedimientos internos de trabajo. </p>

                            <br/>
                            <h2 className="font-weight-bold spacing text-tecalis">
                                <li>Integración y participación</li>
                            </h2>
                            <br/>

                            <p className="font-size-lg text-black-80 mb-6 spacing">La calidad de nuestros servicios
                                depende básicamente de las personas que los desarrollamos. Además de disponer de los
                                medios y métodos más adecuados, debemos potenciar las habilidades y destrezas de las
                                personas que formamos <b>Tecalis</b>, por lo que, la especialización, y la formación
                                de los recursos humanos, han de ser una de nuestras prioridades. </p>

                            <p className="font-size-lg text-black-80 mb-6 spacing">Esta especialización y formación
                                ha de aplicarse tanto en la iniciación, para las personas que se incorporan a la
                                Empresa, como de manera continuada, para conseguir que nuestros procesos tengan los
                                niveles de eficiencia necesarios. </p>
                            <br/>

                            <h2 className="font-weight-bold spacing text-tecalis">
                                <li>Descentralización y Delegación</li>
                            </h2>
                            <br/>

                            <p className="font-size-lg text-black-80 mb-6 spacing">Nuestros procesos no pueden estar
                                basados en la permanente vigilancia. Todas las personas que
                                forman <b>Tecalis</b> han de asumir la responsabilidad sobre sus tareas al nivel que
                                les corresponda. La cuestión de nuestro servicio ha de estar centrada en las
                                personas que lo realizan, de modo que las personas pertenecientes a los diferentes
                                equipos de la empresa tienen la responsabilidad y autoridad para, dentro de su marco
                                de actuación, organizar las actividades para conseguir la satisfacción del cliente.
                            </p>

                            <br/>
                            <h2 className="font-weight-bold spacing text-tecalis">
                                <li>Gestión basada en datos</li>
                            </h2>
                            <br/>

                            <p className="font-size-lg text-black-80 mb-6 spacing">Mantener el máximo nivel de
                                eficiencia y calidad en nuestros servicios necesita de registro de control y
                                mecanismos de medición que permitan evaluar constantemente sus prestaciones. Para
                                ello, una misión del personal de <b>Tecalis</b> es comprobar permanentemente los
                                resultados obtenidos en los procesos, para prevenir cualquier tipo de desviación con
                                respecto a los compromisos adquiridos con los clientes. </p>

                            <br/>
                            <h2 className="font-weight-bold spacing text-tecalis">
                                <li>Seguridad de la información</li>
                            </h2>
                            <br/>

                            <p className="font-size-lg text-black-80 mb-6 spacing">Desde Tecalis se promueve la
                                reducción o eliminación de los riesgos o efectos en la actividad
                                de <b>Tecalis</b> que pudieran derivarse de eventuales fallos de seguridad, teniendo
                                en cuenta las consecuencias de una pérdida de confidencialidad, integridad o
                                disponibilidad de los activos existentes.</p>
                            <p className="font-size-lg text-black-80 mb-6 spacing">Para alcanzar estos principios
                                básicos, <b>Tecalis</b> formula anualmente objetivos de calidad y de seguridad de la
                                información y efectúa el seguimiento de los mismos verificando su cumplimiento y
                                estableciendo las medidas necesarias en caso que dicho cumplimiento se vea
                                amenazado.</p>
                            <p className="font-size-lg text-black-80 mb-6 spacing">La Política de Calidad y
                                Seguridad de la Información es difundida a todo el personal a través de seminarios
                                de formación, así como mediante entrega de copia controlada del presente documento.
                                Además, se coloca otra copia controlada en el tablón de anuncios de Tecalis, así
                                como en el portal web corporativo De esta forma, <b>Tecalis</b> se asegura de que la
                                Política del Sistema Integrado de Gestión es entendida por todo el personal de la
                                empresa y otras partes interesadas. </p>

                        </ul>

                        <br/><br/><br/>

                    </div>
                </div>
            </div>
            {/** / .container */}
        </section>

    </LayoutSecondary>
);

export default QualityPage

export const qualityPageQuery = graphql`
query qualityPageQuery($lang: String!){
    cookies: markdownRemark(
            frontmatter: {
                lang: {
                eq: $lang
            }
                name: {
                eq: "cookies"
            }
            }){
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText
            }
        }
    }
`;


